<template>
  <div class="absolute w-screen h-screen flex flex-col bg-center bg-cover bg-buildings">  <HeaderCoproxi></HeaderCoproxi>
    <div class="pl-5 mr-auto ml-auto flex-grow pr-5  text-center inset-0 text-yellow-light w-screen  bg-opacity-90 flex justify-center pt-20 bg-midnight-blue" >
      <div class="z-0 flex flex-1 justify-center max-w-1920 gap-10">
        <div class="flex flex-col ">
          <div class="flex justify-between">
            <div class="font-bariol text-2xl text-left font-bold self-center">Enquête à résoudre</div>
            <div class="font-bariol text-l text-left text-yellow-light rounded-md bg-midnight-blue-light pr-4 pl-4 pb-3 pt-2 mb-6 font-bold self-start">{{questFinished.length}}{{$t.enquete.indicateur_enquete}} </div>
          </div>

          <div class="flex flex-col gap-3">
            <div class="grid grid-cols-2 gap-6" v-if="courses">
              <EnqueteCard
                theme="blue-quest"
                :module="courses[0].modules[0]"
                @nextstep="$emit('nextstep', $event)"
              />
              <EnqueteCard
                theme="yellow-quest"
                :module="courses[0].modules[1]"
                @nextstep="$emit('nextstep', $event)"
              />
              <EnqueteCard
                theme="green-quest"
                :module="courses[0].modules[2]"
                @nextstep="$emit('nextstep', $event)"
              />
              <EnqueteCard
                theme="pink-quest"
                :module="courses[0].modules[3]"
                @nextstep="$emit('nextstep', $event)"
              />
            </div>
          </div>
        </div>

        <div class="w-5/12 1480:w-3/12">
          <ScoreBox></ScoreBox>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import HeaderCoproxi from "../../components/HeaderCoproxi";
import ScoreBox from "../../components/score-box/ScoreBox";
import EnqueteCard from '@/components/EnqueteCard'
import {mapState} from "vuex";

export default {
  emits: ["nextstep"],
  components: {
    HeaderCoproxi,
    EnqueteCard,
    ScoreBox,
  },
  props: {
    courses: {
      type: Object,
      default: undefined
    }
  },
  computed:{
    ...mapState({
      questFinished: state => state.gamestatus.questFinished
    }),
  },
}
</script>

<style lang="scss" scoped>

</style>
