<template>
  <ShadowButton
    width="25vw"
    height="25vh"
    :theme="buttonTheme"
    :image="image"
    @click="$emit('nextstep', {action: 'next', transition: 'up', theme, 'moduleId': module.id })"
  >
    <div
      class="h-full w-full flex flex-col justify-center text-center"
      :class="{
        'bg-midnight-blue-light/80': status === 'todo',
        'bg-yellow-medium/80': status === 'pending',
        'bg-green/80': status === 'success',
      }"
    >
      <div
        v-if="status !== 'todo'"
        class="absolute top-0 right-0 mt-4 mr-4"
      >
        <img v-if="status === 'pending'" class="m-auto" src="@/assets/icons/icon-pending.svg">
        <img v-else class="m-auto" src="@/assets/icons/icon-success.svg">
        <p class="font-bariol-bold font-bold">{{ (status === 'pending' ? 'En cours' : 'Terminée') }}</p>
      </div>
       <div class="small-title py-4 px-12">
         {{ module.title }}
      </div>
      <div class="mt-5 text-sm flex justify-center text-center font-bariol">
        <span class="underline font-bold">
          {{ (status === 'pending' ? 'Reprendre l\'enquête' : (status === 'success' ? 'Recommencer' : 'Lancer l\'enquête')) }}
        </span>
        <img v-if="status === 'pending'" src="@/assets/icons/icon-fleche-bas-midnight.svg" class="ml-2">
        <img v-else-if="status === 'success'" src="@/assets/icons/icon-fleche-bas-midnight.svg" class="ml-2">
        <img v-else-if="theme === 'yellow-quest'" src="@/assets/icons/icon-fleche-bas-yellow-quest.svg" class="ml-2">
        <img v-else-if="theme === 'green-quest'" src="@/assets/icons/icon-fleche-bas-green-quest.svg" class="ml-2">
        <img v-else-if="theme === 'pink-quest'" src="@/assets/icons/icon-fleche-bas-pink-quest.svg" class="ml-2">
        <img v-else src="@/assets/icons/icon-fleche-bas-blue-quest.svg" class="ml-2">
      </div>
    </div>
  </ShadowButton>
</template>

<script>
  import { mapState } from 'vuex'

  import ShadowButton from '@/components/ShadowButton'

  import { userHasSequence } from '@/composables/user_log'
  
  import { getMediaFromId } from "@/utils/apollo-parser";

  export default {
    components: {
      ShadowButton,
    },
    props: {
      module: {
        type: Object,
        required: true,
      },
      theme: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        image: null,
      }
    },
    computed: {
      ...mapState({
        questFinished: state => state.gamestatus.questFinished
      }),
      buttonTheme() {
        let themeVariant = ''

        switch (this.status) {
          case 'pending':
            themeVariant = ' yellow-medium-selector'
            break

          case 'success':
            themeVariant = ' green-selector'
            break
        }

        switch (this.theme) {
          case 'blue-quest':
            return 'blue-quest-selector' + themeVariant
          
          case 'yellow-quest':
            return 'yellow-quest-selector' + themeVariant
          
          case 'green-quest':
            return 'green-quest-selector' + themeVariant

          case 'pink-quest':
            return 'pink-quest-selector' + themeVariant
        }

        return 'blue-quest-selector' + themeVariant
      },
      status() {
        if (!this.module) {
          return 'todo'
        }

        let sequencesDone = 0

        // Count sequence done
        this.module.sequences.forEach((sequence) => {
          if (userHasSequence(sequence.id)) {
            sequencesDone += 1
          }
        })

        if (this.questFinished.includes(this.module.id)) {
          return 'success'
        } else if (sequencesDone > 0) {
          return 'pending'
        }

        return 'todo'
      }
    },
    watch: {
      module: {
        async handler(module) {
          if (!module) {
            this.image = null
            return
          }

          // Load image asynchronously
          if (module.metas && module.metas.thumbnail) {
            const mediaQueryResult = await getMediaFromId(module.metas.thumbnail)

            this.image = process.env.VUE_APP_MEDIA_URL + mediaQueryResult.data.item.path
          } else {
            this.image = 'default'
          }
        },
        immediate: true
      }
    }
  }
</script>